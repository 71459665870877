


import { Component, Vue } from "vue-property-decorator";
import '@/vuebar';
import { BASE_API_URL } from "@/config";
import Axios from "axios";

let w = window as any;
const Vuebar = w.Vuebar as any;
Vue.use(Vuebar);
import moment from "moment";
import UpdateUserInfo from "@/views/UpdateUserInfo.vue";
import UpdateUserStatus from "../UpdateUserStatus.vue";
import saveAs from "save-as";
import _ from "lodash";
import Debounce from '@/decorators/Debounce';
import {mixins} from "vue-class-component";
import CommonMixin from "@/mixins/common.mixin";



@Component({ components: { UpdateUserStatus, UpdateUserInfo } })
export default class BorrowerListComponent extends mixins(CommonMixin) {
  public allBorrowers = [];
  public loanStatus = null;
  public selectedReason = 'AC030';
  public reasons = [
    {
      value: 'AC030',
      label: 'Applications Approved but Not Accepted'
    },
    {
      value: 'AC040',
      label: 'Applications Denied'
    },
    {
      value: 'AC050',
      label: 'Applications Withdrawn'
    },
    {
      value: 'AC060',
      label: 'File Closed for Incompleteness'
    },
    {
      value: 'AC062',
      label: 'Pre-Approval Requests Denied'
    },
    {
      value: 'AC064',
      label: 'Pre-Approval Requests Approved but not Accepted'
    }
  ]
  public currentBorrowerIndex: number = 0;
  public borrowers = [];
  public searchLoanType = "All";
  public dotLoader = false;
  public borrowerLoader = false;
  public initialPagination = { start: 0, end: 15 };
  public pagination: any = JSON.parse(JSON.stringify(this.initialPagination));
  public hideLoadMore: Boolean = null;
  public statusChangingIndex: any = null;
  public activeOrDeactiveIndex: any = null;
  public currentLengthOnScroll = 0;
  public totalBorrowerCount = 0;
  public searchName = "";
  public skip = 0;
  public searchByType = null;
  public searchLoanString = "";
  public personalInfo: any = [];
  public borrowersLoanTxnId = null;
  public showPopUp = false;
  public showPopUpForInfo = false;
  public list: any = null;
  public userInfo: any = {};
  public loadMoreScroll: boolean = true;

  public updatedLoan = {
    loanTxnId: null,
    enumerationType: null
  };

  get userAccess() {
    return this.$store.state.sessionObject.access;
  }
  /**************************************************************************************************************************
   *                      This component is used for fetching Borrower's List for Broker and Processor.                     *
   *                      Function in working ............ (Not completed yet)                                              *
   **************************************************************************************************************************/
  public async getTotalBorrowerCount() {
    try {
      let sharePipeLineAccess = false;
      if (this.$userType == "MLO" || this.$userType == "Admin Staff") {
        let sharePipeLine = this.userAccess.filter(
          e => e.component == "sharePipeline"
        );
        sharePipeLineAccess = sharePipeLine[0].canAccess;
      }
      let response = await Axios.post(
        BASE_API_URL + "borrower/getTotalBorrowerCount",
        {
          userId: this.$userId,
          userType: this.$userType,
          brokerId: this.$brokerId,
          sharePipeLineAccess: sharePipeLineAccess
        });
      this.totalBorrowerCount = response.data.totalBorrowerCount;
    } catch (error) {
      console.log(error);
    }
  }

  @Debounce
  public async getBorrowers(mountCall: boolean = false) {
    try {
      const limit = 50;
      this.skip = mountCall ? 0 : this.skip + limit;
      if (mountCall) {
        this.borrowerLoader = true;
      }
      let sharePipeLineAccess = false;
      if (this.$userType == "MLO" || this.$userType == "Admin Staff") {
        let sharePipeLine = this.userAccess.filter(
          e => e.component == "sharePipeline"
        );
        sharePipeLineAccess = sharePipeLine[0].canAccess;
      }
      let response = await Axios.post(
        BASE_API_URL + "superAdmin/getBorrowers",
        {
          brokerId: this.$brokerId,
          userId: this.$userId,
          fieldName: this.searchByType,
          sharePipeLineAccess: sharePipeLineAccess,
          type: this.$userType,
          options: {
            skip: this.skip,
            limit,
          },
          searchString:
            this.searchLoanString != "" && this.searchLoanString != null
              ? this.searchLoanString
              : null,
          desc: true
        });
      if (mountCall) {
        this.borrowers = response.data.borrowers;
        this.allBorrowers = response.data.borrowers;
      } else {
        this.borrowers = this.borrowers.concat(response.data.borrowers);
        this.allBorrowers = this.allBorrowers.concat(response.data.borrowers);
      }
      this.currentLengthOnScroll = this.borrowers.length;
    } catch (error) {
      console.error(error);
    } finally {
      this.borrowerLoader = false;
      this.loadMoreScroll = false;
    }
  }

  /*********************************************************************************
   *      function return date in monthName/dd/yyyy formate if avaliable           *
   *********************************************************************************/
  public getClosingDate(data) {
    let closingDate = null;
    if (data.closingDate != null) {
      closingDate = moment(data.closingDate).format("MMMM D, YYYY");
      return closingDate != null ? closingDate : "-----";
    } else {
      return (closingDate = "-------");
    }
  }
  public async showMoreDataOnScroll() {
    if (!this.loadMoreScroll && this.currentLengthOnScroll < this.totalBorrowerCount) {
      this.loadMoreScroll = true
      await this.getBorrowers(false);
      this.loadMoreScroll = false;
    }
  }
  public async showMessage(type) {
    try {
      this.loanStatus = type;
      this.$modal.show("noData");
    } catch (error) {
      console.error(error);
    }
  }

  public async searchLoan() {
    try {
      let allData = this.allBorrowers;
      let searchData = [];
      this.borrowers = [];
      if (this.searchLoanType != "All") {
        allData.forEach(data => {
          if (
            data.loanStatus[data.loanStatus.length - 1].name ==
            this.searchLoanType
          ) {
            searchData.push(data);
          }
        });
        this.borrowers = searchData;
      } else {
        this.borrowers = allData;
      }
    } catch (error) {
      console.log(error);
    }
  }

  public getBorrowerContact(loanTxnId) {
    this.$router.push({
      path: this.$getCurrentUserTypePath("borrower-contact"),
      query: { loanTxnId: loanTxnId }
    });
  }
  /****************************************************************************************************************************
   *    this function used for open activation/deactivation modal ('activeOrDeactiveModal) in UdateUserStatus Component       *                            *
   ****************************************************************************************************************************/
  // public openActiveOrDeactiveModal(loanTxnId) {
  //   this.borrowersLoanTxnId = loanTxnId;
  //   // this.showPopUp = true
  //   this.$refs.updateUserInfo['openModalOrClose'](loanTxnId);
  //   // this.openActiveOrDeactive(loanTxnId)
  // }

  public async openActiveOrDeactiveModal(borrower, i) {
    this.currentBorrowerIndex = i;
    this.$modal.show('activeOrDeactiveAccount')
  }

  public cancelModal() {
    this.$modal.hide('activeOrDeactiveAccount')
    this.$snotify.info("No changes");
  }

  public async submitModal() {
    this.$modal.hide('activeOrDeactiveAccount')

    if (this.borrowers[this.currentBorrowerIndex].borrowerLength === 1) {
      let borrower = this.borrowers[this.currentBorrowerIndex];
      let loanTxnId = borrower.loanTxnId;
      let response = await Axios.post(
          BASE_API_URL + "common/deactivateUser",
          {
            loanTxnId: loanTxnId,
            activate: !borrower.user.userActive,
            userType: borrower.user.userType,
            reason: this.selectedReason,
          }
      );
      let message = response.data.userActive
          ? "User has been activated"
          : "User has been deactivated";
      this.$snotify.success(message);

      /**
       * Refresh the list
       */
      await this.getBorrowers(true)
    } else {
      await this.handleForMultipleBorrower(this.borrowers[this.currentBorrowerIndex]);
    }
  }

  public async handleForMultipleBorrower(borrower) {
    try {
      this.userInfo = JSON.parse(JSON.stringify(borrower));
      this.borrowersLoanTxnId = this.userInfo;
      this.$refs.updateUserInfo["openModalOrClose"](this.userInfo);
    } catch (error) {
      console.log(error);
    }
  }

  public async openSelectInactiveReasonModal(currentBorrowerIndex) {
    this.currentBorrowerIndex = currentBorrowerIndex;
    this.$modal.show('selectInactiveReason');
  }

  public async cancelSelectInactiveReasonModal() {
    this.$modal.hide('selectInactiveReason');
  }

  public async submitSelectInactiveReasonModal() {
    this.$modal.hide('selectInactiveReason');

    try {
      let borrower = this.borrowers[this.currentBorrowerIndex];

      await Axios.post(
          BASE_API_URL + "common/deactivateUser",
          {
            loanTxnId: borrower.loanTxnId,
            activate: false,
            userType: borrower.user.userType,
            reason: this.selectedReason,
          }
      );
    
      this.$snotify.success("Reason set successfully");
    } catch (e) {
      this.$snotify.error("Something went wrong! Please try after sometime.");
    } finally {
      /**
       * Refresh list
       */
      await this.getBorrowers(true);
    }
  }

  getReasonLabel(value) {
    const reason = this.reasons.find(reason => reason.value === value);
    return _.get(reason, 'label');
  }

  showPersonalDetailModal(modalName, borrower) {
    let data = {
      loanTxnId: borrower.loanTxnId,
      userType: "Borrower",
      modalName: modalName
    };

    this.personalInfo = [{ ...borrower, ...data }];
    this.$refs.updateUserStatus["openModalOrClose"](this.personalInfo);
  }
  public async updateBorrowerInfo(updatedData) {
    await this.getBorrowers(true);
  }
  public async getLoanType() {
    try {
      let response = await Axios.post(
        BASE_API_URL + "common/getLoanType",
        {
          loanType: "loanstatus"
        });
      this.loanStatus = response.data.loanStatus;
    } catch (error) {
      console.log(error);
    }
  }

  public getValueOnChange() {
    this.searchLoanString = null;
    if (this.searchByType == null) {
      this.getBorrowers(true);
      this.getLoanType();
    }
  }

  public async downloadUploadedFile(loanTxnId) {
    try {
      this.$store.state.wemloLoader = true;
      let response = await Axios.post(
        BASE_API_URL + "common/downloadUploadedFile",
        { loanTxnId: loanTxnId },
        { responseType: "blob" }
      );
      if (response.status == 200 && response.data) {
        saveAs(response.data, loanTxnId + `.zip`);
      } else {
        this.$snotify.clear();
        this.$snotify.error("No document uploaded yet!");
      }
      this.$store.state.wemloLoader = false;
    } catch (error) {
      this.$store.state.wemloLoader = false;
    }
  }

  canChangeEnumerationType(loan) {

    /**
     * The rule to change the enumeration type is
     * If the enumeration type is mismo, nobody can change it back to fannieMae
     * If the loan is already submitted and not moved to lead the user cannot change the type
     * unless they are Processor or Super Admin
     */

    if (_.get(loan, 'loanInformation.enumerationType') === 'mismo') {
      return false;
    }

    if (['Wemlo Processor', 'Super Admin'].includes(this.$userType)) {
      return true;
    }

    if (_.get(loan, 'submissionStepId') === 'submission' && !_.get(loan, 'moveToLead')) {
      return false;
    }

    return true;
  }

  async updateEnumerationType(loanTxnId, enumerationType) {
    try {
      await Axios.post(BASE_API_URL + 'los/updateEnumerationType', {
        loanTxnId,
        enumerationType,
      });

      this.$snotify.success("Type updated successfully");

      this.openAfterChangingTypeModal(loanTxnId, enumerationType);

    } catch (error) {
      this.$snotify.error("Something went wrong! Please try after sometime.");
    }
  }

  openAfterChangingTypeModal(loanTxnId, enumerationType) {
    this.updatedLoan = {
      loanTxnId,
      enumerationType
    };

    this.$modal.show('afterChangingType');
  }

  closeAfterChangingTypeModal() {
    this.$modal.hide('afterChangingType');

    this.updatedLoan = {
      loanTxnId: null,
      enumerationType: null
    };
  }

  async mounted() {
    await this.getLoanType();
    await this.getBorrowers(true);
    await this.getTotalBorrowerCount();
    if (this.$userType === 'Wemlo Processor') {
      this.reasons.length = 4;
    }
  }
}
